import React from 'react';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import DynamicChatbot from './components/DynamicChatbot/DynamicChatbot';
import NotFound from './components/NotFound/NotFound';
import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/:id",
    element: <DynamicChatbot />,
    errorElement: <NotFound />,
  },
]);

const rootElement = document.getElementById("root");

if (!rootElement) {
  console.error("Could not find root element");
} else {
  createRoot(rootElement).render(
    <RouterProvider router={router} />
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();