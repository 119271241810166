import React from 'react';
import './NotFound.css';

const NotFound = () => (
  <div id="main">
    <div className="fof">
      <h1>Erreur 404</h1>
      <h3>Aucun chatbot trouvé</h3>
    </div>
  </div>
);

export default NotFound;