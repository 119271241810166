import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import NotFound from '../NotFound/NotFound';
import './DynamicChatbot.css';

declare global {
  interface Window {
    botpressWebChat: any;
  }
}

const DynamicChatbot = () => {
  const { id } = useParams();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [chatbotId, setChatbotId] = useState(null);
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchChatbotId = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/functions/v1/chatbot-get-id-from-path?pathId=${id}`);
        if (response.ok) {
          const { chatbotId } = await response.json();
          setChatbotId(chatbotId);
          setError(false);


          const chatbotConfig = {
            botId: chatbotId,
            hostUrl: "https://cdn.botpress.cloud/webchat/v1",
            messagingUrl: "https://messaging.botpress.cloud",
            clientId: chatbotId,
            composerPlaceholder: "Chat avec l'Assistant IA",
            botConversationDescription: "Chat avec l'Assistant IA",
            botName: "Assistant IA",
            avatarUrl: "https://autonomya.fr/wp-content/uploads/2023/11/logo-rogne.png",
            stylesheet: "https://webchat-styler-css.botpress.app/prod/code/88ea4654-f794-4fd4-9b4b-a8258614c091/v45413/style.css",
            showBotInfoPage: false,
            enableConversationDeletion: true,
            hideWidget: true,
            disableAnimations: true,
            closeOnEscape: false,
            showConversationsButton: false,
            enableTranscriptDownload: false,
            showCloseButton: false,
          };

          window.botpressWebChat.init(chatbotConfig);
          window.botpressWebChat.onEvent(function () {
            window.botpressWebChat.sendEvent({ type: 'show' })
          }, ['LIFECYCLE.LOADED']);
        } else {
          throw new Error('Failed to fetch Assistant IA from API');
        }
      } catch (error) {
        console.error("Erreur lors de la récupération de l'Assistant IA :", error);
        setError(true);
      }
      setIsLoading(false);
    };

    fetchChatbotId();

  }, [id]);

  if (isLoading) {
    // TODO(kevin): improve loading spinner
    return <div className="center">Chargement...</div>;
  }

  if (error) {
    return <NotFound />;
  }

  return null;
};

export default DynamicChatbot;